<template>
	<div class="GroupOverview">
		<daohang></daohang>
		<banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
		<!-- 关于企业 -->
		<div class="about-bg">
			<div class="about" id="about">
				<div class="about-top">
					<div class="about-top-l">
						<p> 公司介绍 </p>
						<p> SKV MICRO Co.Ltd是一家本土原创高性能芯片设计公司。基于过去20多年深耕国内消费及通讯芯片市场所积累的研发经验，在充分吸收国外先进芯片设计技术和设计方法的前提下，
							公司的核心主创人员立足于国内现有的商业化芯片生产工艺，在电路设计结构，电路设计方法和芯片校准流程等技术要素上充分创新，在事关国计民生核心工业需求的高性能、
							高精度模拟芯片领域实现逐步突破。天行微电的芯片产品，涵盖了精密信号链、集成模拟前端、高精度模拟数字转换、精密传感器调理以及硅基射频毫米波，
							高性能数模混合信号芯片及无线通讯等。
						</p>
						<p>天行有常，见微知著。SKV MICRO以技术创新为己任，以大国重器为目标，认认真真做设计，老老实实做产品，践行自力更生，自强不息的企业信念。</p>
					</div>
					<div class="about-top-r">
						<img src="../assets/img/guanyu/guanyu.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!-- 企业文化 -->
		<div class="qywh" id="CultureInfo">
			<h1>企业文化</h1>
			<div class="qywh-item">
				<div class="qywh-item1">
					<div>
						<div class="qywh_icon">
							<img src="../assets/img/guanyu/jiazhi.png" alt="" />
						</div>
					</div>
					<p class="qywh-text">价值观</p>
					<p class="qywh-text2">自力更生，艰苦奋斗</p>
				</div>
				<div class="qywh-item1">
					<div>
						<div class="qywh_icon">
							<img src="../assets/img/guanyu/shiming.png" alt="" />
						</div>
					</div>
					<p class="qywh-text">使命</p>
					<p class="qywh-text2">天行有常，见微知著</p>
				</div>
				<div class="qywh-item1">
					<div>
						<div class="qywh_icon">
							<img src="../assets/img/guanyu/yuanjing.png" alt="" />
						</div>
					</div>
					<p class="qywh-text">愿景</p>
					<p class="qywh-text2">本土原创高性能模拟芯片公司</p>
				</div>
			</div>
		</div>
		<!-- 发展历程 -->
		<div class="DevelopmentHistory" id="history">
			<div class="DevelopmentHistory_cont">
				<div class="DevelopmentHistory_cont_left">
					<p>{{fzArr[curIndex].year}}</p>
					<p>发展历程</p>
				</div>
				<div class="DevelopmentHistory_cont_center">
					<div class="swiper-container swiper1">
						<div class="swiper-wrapper">
							<div v-for="item,index in fzArr" :key="index" class="swiper-slide">
								<div style="line-height: 25px;font-size: 18px;">
									<p v-for="item2,index2 in item.descs" :key="index2">·&nbsp;{{ item2 }}</p>
								</div>
							</div>
							<div class="swiper-slide" style="width: 100%;height: 380px;"></div>
						</div>
						<div class="swiper-scrollbar"></div>
					</div>
				</div>
				<div class="DevelopmentHistory_cont_right">
					<div class="swiper-container swiper2">
						<div class="swiper-wrapper">
							<div v-for="item,index in fzArr" :key="index" class="swiper-slide year_slide">
								<div>{{ item.year }}</div>
							</div>
						</div>
					</div>
					<div v-if="curIndex != 0" class="swiper-button-prev q-prev" @click="prevEvt"></div>
					<div v-if="curIndex != fzArr.length-1" class="swiper-button-next q-next" @click="nextEvt"></div>
				</div>
			</div>
		</div>
		<!-- 总裁寄语 -->
		<div class="President">
			<div class="President_content">
				<div class="President-title">
					<p>联系我们</p>
				</div>
				<p class="address_text">公司在珠海设立技术服务中心，在北京、南京成都、西安设立研发及销售中心</p>
				<div class="lxaddress">
					<img src="../assets/img/guanyu/202202231038584241.jpg" alt="">
				</div>
				<div class="e-list">
					<div class="e-item" v-for="item,index in fgs" :key="index">
						<div class="e-name s24">
							{{ item.name }}
						</div>
						<div class="e-c">
							<div class="e-one">
								<div class="e-icon s16">
									<div class="e-icon1">
										<img src="../assets/img/guanyu/a7.png" alt="">
									</div>
									<div class="e-icon2">
										<img src="../assets/img/guanyu/a7-b.png" alt="">
									</div>
								</div>
								<div class="e-text s16">{{ item.tel }}</div>
							</div>
							<div class="e-one">
								<div class="e-icon s16">
									<div class="e-icon1">
										<img src="../assets/img/guanyu/a8.png" alt="">
									</div>
									<div class="e-icon2">
										<img src="../assets/img/guanyu/a8-b.png" alt="">
									</div>
								</div>
								<div class="e-text s16">{{ item.email }}</div>
							</div>
							<!-- <div class="e-one">
								<div class="e-icon s16">
									<div class="e-icon1">
										<img src="../assets/img/guanyu/a9.png" alt="">
									</div>
									<div class="e-icon2">
										<img src="../assets/img/guanyu/a9-b.png" alt="">
									</div>
								</div>
								<div class="e-text s16">{{ item.addr }}</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<footer2></footer2>
	</div>
</template>

<script>
	import daohang from "../components/nav.vue";
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import Swiper from 'swiper'; // 注意引入的是Swiper
	import 'swiper/css/swiper.min.css' // 注意这里的引入
	export default {
		name: "App",
		data() {
			return {
				h1: "关于我们",
				P1: "自主创新丨品质至上丨团结协作丨成就客户",
				Img1: require("../assets/img/guanyu/bannerjituan.png"),
				dataPopRadio: {},
				swiper: null,
				swiper2: null,
				curIndex: 0,
				fgs: [{
					name: 'SKV MICRO Co.Ltd',
					tel: ' +86-13322877139',
					email: 'sales@skvmicro.com',
					// addr: '江苏省无锡市新吴区弘毅路8号金乾座20层'
				}
					// , {
					// 	name: 'SKV MICRO Co.Ltd',
					// 	tel: ' 400-8090-866/+86-755-23595309',
					// 	email: 'runic_overseas@163.com',
					// 	// addr: '江苏省无锡市新吴区弘毅路8号金乾座20层'
					// }, {
					// 	name: 'SKV MICRO Co.Ltd',
					// 	tel: ' 400-8090-866/+86-755-23595309',
					// 	email: 'runic_overseas@163.com',
					// 	// addr: '江苏省无锡市新吴区弘毅路8号金乾座20层'
					// }, {
					// 	name: 'SKV MICRO Co.Ltd',
					// 	tel: ' 400-8090-866/+86-755-23595309',
					// 	email: 'runic_overseas@163.com',
					// 	// addr: '江苏省无锡市新吴区弘毅路8号金乾座20层'
					// }
				],
				fzArr: [{
					year: 2023.6,
					descs: [
						'公司成立',
					]
				}, {
					year: 2023.10,
					descs: [
						'首次流片成功',
					]
				}, {
					year: 2023.12,
					descs: [
						'用户测试通过',
					]
				}, {
					year: 2024.3,
					descs: [
						'首批产品出货',
					]
				}]
			};
		},
		components: {
			daohang,
			footer2,
			banner2,
		},
		created() {
			this.$nextTick(() => {
				this.toLocal();
			});
		},
		mounted() {
			this.getSwiper();
			let _this = this;
			_this.$nextTick(function () {
				window.addEventListener("scroll", _this.handleScroll);
			});
		},
		methods: {
			toLocal() {
				//查找存储的锚点id
				let Id = localStorage.getItem("toId");
				let toElement = document.getElementById(Id);
				//锚点存在跳转
				if (Id) {
					toElement.scrollIntoView();
					localStorage.setItem("toId", "");
				}
			},
			getSwiper() {
				var self = this;
				this.swiper = new Swiper(".swiper1", {
					// loop: true, // 无缝
					paginationClickable: false,
					autoHeight: true,
					slidesPerView: 'auto', // 一组三个
					spaceBetween: 30, // 间隔
					slide: true,
					direction: 'vertical',
					mousewheel: true,
					normalizeSlideIndex: false,
					scrollbar: {
						el: '.swiper-scrollbar',
					},
					on: {
						slideChange() {
							self.getActiveIndex();
						},
					},
				})
				// this.swiper.scrollbar.$dragEl.css('background', 'red');

				this.swiper2 = new Swiper(".swiper2", {
					paginationClickable: false,
					autoHeight: true,
					slidesPerView: 5,//'auto', //'auto', // 一组三个
					spaceBetween: 30, // 间隔
					slide: true,
					direction: 'vertical',
					slideToClickedSlide: true,
					normalizeSlideIndex: false,
					on: {
						slideChange() {
							self.getActiveIndex2();
						},
					},
				})
			},
			getActiveIndex() {
				this.curIndex = this.swiper.activeIndex
				this.swiper2.slideTo(this.curIndex, 500, false);
			},
			getActiveIndex2() {
				this.curIndex = this.swiper2.activeIndex
				this.swiper.slideTo(this.curIndex, 500, false);
			},
			prevEvt() {
				this.curIndex = this.curIndex - 1;
				this.swiper2.slideTo(this.curIndex, 500, false);
				this.swiper.slideTo(this.curIndex, 500, false);
			},
			nextEvt() {
				this.curIndex = this.curIndex + 1;
				this.swiper2.slideTo(this.curIndex, 500, false);
				this.swiper.slideTo(this.curIndex, 500, false);
			}

		},
	};
</script>

<style scoped>
	.GroupOverview {
		width: 100vw;
		margin: 0 auto;
	}

	.about {
		/* height: 700px; */
		position: relative;
		margin: 100px 100px 50px 100px;
	}

	/* .about-bg {
	
} */

	.about-top {
		position: relative;
		display: flex;
		justify-content: center;
	}

	.about-top-l {
		/* margin-right: 15px; */
		width: 35%;
	}

	.about-top-l :nth-child(1) {
		font-weight: bolder;
		font-size: 28px;
		color: #cfb68b;
	}

	.about-top-l :nth-child(2) {
		margin-top: 10px;
		text-indent: 2em;
    line-height: 30px;
    letter-spacing: 0.034em;
    visibility: visible;
		/* font-size: 18px; */
	}

	.about-top-l :nth-child(3) {
		text-indent: 2em;
    line-height: 30px;
    letter-spacing: 0.034em;
    visibility: visible;
		/* font-size: 18px; */
	}

	.about-top-r img {
		/* width: calc(600 * 100vw / 1920);
		; */
		height: calc(1200 * 100vh / 1920);
		;
	}

	.about-top-r {
		margin-left: 3%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qywh {
		width: 100%;
		height: 560px;
		/* background-color: rgba(248, 248, 248, 1); */
		background-image: url("../assets/img/guanyu/b21.png");
	}

	.qywh h1 {
		color: #ffffff;
		font-size: 40px;
		font-weight: bolder;
		line-height: 134px;
		text-align: center;
	}

	.qywh-item {
		/* margin: 30px 200px; */
		/* justify-content: center;
		display: flex; */
		justify-content: space-around;
		display: flex;
		padding: 0% 10%;
		/* height: 100%; */
	}

	.qywh-item1 {
		/* margin-right: 35px; */
		position: relative;
		width: 25%;
		/* height: 70%; */
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding-bottom: 110px
	}

	.qywh_icon {
		border-radius: 100%;
		width: 90px;
		height: 90px;
		margin: auto;
		margin-top: 45px;
		text-align: center;
		background-color: #3491e5;
	}

	.qywh_icon>img {
		height: 60%;
		width: 60%;
		margin: auto;
		margin-top: 20%;
	}

	.qywh-text {
		/* color: rgba(68, 68, 68, 1); */
		color: #cfb68b;
		font-size: 30px;
		font-weight: bolder;
		text-align: center;
		margin-top: 30px;
	}

	.qywh-text2 {
		text-align: center;
		color: rgba(68, 68, 68, 1);
		font-size: calc(25 * 100vw / 1920);
		line-height: 28px;
		margin-top: 20px;
	}


	/* 总裁寄语 */
	.President {
		/* width: 1200px; */
		/* padding: 44px; */
		padding: 0% 10%;
		padding-top: 50px;
		padding-bottom: 70px;
	}

	.President-title>p {
		font-size: 35px;
		font-weight: bolder;
		line-height: 45px;
		text-align: center;
		color: #cfb68b;
	}

	.lxaddress {
		display: flex;
		justify-content: center;
	}

	.address_text {
		text-align: center;
		font-size: 18px;
		line-height: 80px;
		font-weight: 600;
	}

	.lxaddress>img {
		width: 100%;
		/* height: 450px; */
	}

	/* 发展历程 */
	.DevelopmentHistory {
		background-color: gray;
		/* padding-top: 50px;
		padding-bottom: 20px; */
		padding: 3% 10% 3% 10%;
		/* width: 100vw; */
		height: 650px;
		background-image: url("../assets/img/guanyu/fazhan_bg.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.DevelopmentHistory_cont {
		/* width: 1200px; */
		background-color: #fff;
		/* box-shadow: -5px 5px 10px -4px #f6eeee, 5px 5px 10px -4px #f6eeee; */
		box-shadow: #cfc3c3 0px 0px 20px;
		height: 90%;
		margin: auto;
		display: flex;
		padding: 20px 40px 20px 40px;
	}

	.DevelopmentHistory_cont_left {
		width: 20%;
	}

	.DevelopmentHistory_cont_left :nth-child(1) {
		font-size: 50px;
		color: #cfb68b;
		font-weight: bolder;
		text-shadow: 2px 4px 10px #3c3434;
	}

	.DevelopmentHistory_cont_left :nth-child(2) {
		text-align: left;
		margin-top: 40px;
		font-size: 28px;
		color: #cfb68b;
		font-weight: bolder;
	}

	.DevelopmentHistory_cont_center {
		overflow: hidden;
		height: 100%;
		width: 50%;
	}

	.DevelopmentHistory_cont_right {
		overflow: hidden;
		margin-left: 45px;
		width: 20%;
		background-color: #000;
		/* color: #bb7d13; */
		position: relative;
	}


	ul {
		background-color: #fff;

		li {
			list-style: none;
			font-size: 20px;
		}
	}

	.swiper-slide {
		padding: 10px 15px;
	}

	.year_slide {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: bolder;
		width: 100%;
	}

	.swiper-slide-active {
		background-color: #000;

	}

	.backgroundFloat {
		width: 100%;
		height: 40px;
		background-color: #d1d1d1;
		opacity: 0.2;
		position: absolute;
		top: 190px;
	}

	.swiper1 {
		height: 100%;
		color: #cfb68b;
		font-weight: bold;
	}

	.swiper2 {
		height: 90%;
		padding-top: 35px;
		color: #cfb68b;
	}

	.swiper2 .swiper-slide-active {
		font-size: 20px;
		background-color: #fff;
	}

	.swiper-button-prev:after {
		transform: rotate(90deg);
		color: #fff;
		font-size: 25px;
	}

	.swiper-button-next:after {
		transform: rotate(-90deg);
		color: #fff;
		font-size: 25px;
	}

	.q-next {
		transform: rotate(-180deg);
		margin-top: 0;
		top: auto;
		bottom: 1rem;
		left: 0;
		width: 100%;
		height: 10px;
		background-size: auto 10px;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.q-prev {
		margin-top: 0;
		top: 1rem;
		left: 0;
		width: 100%;
		height: 10px;
		background-size: auto 10px;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.e-list {
		margin-top: 30px;
		/* width: 1200px; */
		display: flex;
		flex-wrap: wrap;
		font-size: 0;
	}

	.e-item {
		border-radius: 10px;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		padding: 25px;
		width: 30%;
		padding-bottom: 50px;
		margin-bottom: 20px;
		margin-right: 20px;
		background-color: #ffffff;
		box-shadow: 0px 0.14rem 0.3rem 0px rgba(0, 0, 0, 0.14);
	}

	.e-item:hover {
		background-color: #1b2025;
		color: #fff;
	}

	.e-item:hover .e-icon2 {
		display: inline-block;
	}

	.e-item:hover .e-icon1 {
		display: none
	}

	.e-item:hover .e-name:after {
		background-color: #cfb68b;
	}

	.e-name {
		text-wrap: nowrap;
		color: #cfb68b;
		position: relative;
		font-size: 20px;
		/* font-weight: bold; */
		padding-bottom: 20px;
		margin-bottom: 25px;
		;
	}

	.e-name:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 40px;
		height: 4px;
		background-color: #cfb68b;
	}

	.e-text {
		font-size: 18px;
		/* font-weight: 600; */
	}

	.e-icon2 {
		display: none;
		margin-right: 10px;
	}

	.e-icon1 {
		margin-right: 10px;
	}

	.e-one {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
</style>