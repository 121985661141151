<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<div class="btn">
			<div class="btn_cont">
				<div :class="active === item.type ? 'active' : ''" v-for="(item, index) in newsTypes" :key="index"
					@click="NewsDetails(item)">
					{{ item.title }}
				</div>
			</div>
		</div>
		<div class="NewsDetails">
			<div class="NewsDetails_cont">
				<div class="NewsDetails_cont_title">
					<h1>{{curNews.title}}</h1>
					<div class="NewsDetails_cont_title_span">
						<img src="../../assets/img/newsDetail/矩形.png" alt="" /><span>发布时间：{{curNews.time}}&ensp;&ensp;</span>
					</div>
				</div>
				<div class="NewsDetails_cont_text">
					<!-- <div v-html="curNews.content"></div> -->
					<!-- <div style="max-width: 677px;line-height: 1.75em;">
						<p :style="">
							随着微电子产业的发展，各种封装技术不断涌现，对于封装材料的内应力、导热性、电性能都提出了更高的要求。本文介绍了聚合物微电子封装的主要材料，
							包括环氧树脂、有机硅、聚酰亚胺。由于封装的无铅化发展以及高功率导致的散热需求，导电胶和热界面材料成了在封装中研究的较多热门材料。根据产品的使用环境，
							通过加速试验（温度循环、高加速应力试验、疲劳试验等）可以评估封装的可靠性。为探索封装失效的原因，需要对产品进行失效分析。本文介绍了常用的失效分析技术，
							具体描述了倒装芯片的底填胶的失效分析，并根据失效原因对底填材料提出了性能要求。封装材料的冷热冲击和湿热稳定性是影响可靠性的重要因素，湿气会导致封装“爆米花效应”、
							电化学迁移等后果从而使器件失效。为此，本文探究了湿气对半导体器件的影响及扩散机理，并对影响材料吸水性能的因素（如极性、自由体积、材料微相分离等）进行了综述。
							最后，还对微电子封装材料的未来发展进行了展望。</p>
						<img src="https://img.sosozhaofang.com/SKVMICRO/640.webp" alt="">
						<p
							style="color: rgb(35, 31, 32);font-family: Optima-Regular, PingFangTC-light;font-size: 14px;text-indent: 2em;letter-spacing: 0.034em;visibility: visible;">
							为了近代科学技术发展的一个重要标志。随着科学技术的发展，电子器件的体积越来越小，集成度越来越高，对于电子封装材料的要求也越来越严格。微电子封装技术也逐渐成了影响微电子技术发展的重要因素之一。电子封装材料涉及多门学科，例如机械、电气和工业工程、化学、物理甚至市场营销。好的电子封装材料需要好的可靠性、适用性、低成本、市场需求大、可制造性和高产率。
						</p>

						<p
							style="color: rgb(35, 31, 32);font-family: Optima-Regular, PingFangTC-light;font-size: 14px;text-indent: 2em;letter-spacing: 0.034em;visibility: visible;">
							随着科技发展，电子封装的新技术、新材料、新工艺以及新产品不断涌现。一级封装（集成电路等电子器件的封装）向高密度、轻量化、窄节距、低分布、多功能以及适用于表面安装的方向发展；而二级封装（板级封装）的连接方式从通孔插装（PTH）为主变为了表面贴装（SMT）为主，并向基板类型的多样化、软焊料无铅化、模塑料和印制板的无卤素化推进。在开发更小、更快、更轻和更便宜的电子设备需求的驱动下，电子封装从
							20 世纪 60 年代的通孔技术，发展到 20世纪 80年代的表面贴装技术，进而迅速发展到 21世纪初的芯片级封装（CSP），如图1所示。
						</p>
						<p style="text-align: center;">
							<img style="width: 417px" src="https://img.sosozhaofang.com/SKVMICRO/640%20%281%29.webp" alt="">
						</p> -->

					<!-- </div> -->
					<div class="main_content" v-for="item,index in curNews.content" :key="index">
						<p v-if="item.type == 0" class="content_text">
							<span>
								{{item.text}}
							</span>
						</p>
						<p v-else-if="item.type == 1" class="content_img">
							<img :src="item.img" alt="">
						</p>
						<p v-else-if="item.type == 2" class="content_title_one">
							<span class="title_one">
								{{item.text}}
							</span>
						</p>
						<p v-else-if="item.type == 3" class="content_title_two">
							<span>
								{{item.text}}
							</span>
						</p>
						<p v-else-if="item.type == 4" class="content_title_three">
							<span>
								{{item.text}}
							</span>
						</p>
						<p v-else-if="item.type == 5" class="content_title_fourth">
							<span>
								{{item.text}}
							</span>
						</p>

					</div>
				</div>
				<!-- 上一篇 下一篇 -->
				<Next ref="childData" @changeItem="changeItem"></Next>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
	import footer2 from "../../components/footer.vue";
	import banner2 from "../../components/banner.vue";
	import nav2 from "../../components/nav.vue";
	import Next from "../../components/NextLastArtcle.vue";
	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
			Next,
		},
		computed: {
			newsList() {
				return this.$store.state.newsList;
			},
		},

		data() {
			return {
				active: "0",
				h1: "资讯详情",
				Img1: 'https://img.sosozhaofang.com/SKVMICRO/newsbanner.png',// require("../../assets/img/news/banner.png"),
				artcleID: 0,
				curNews: {},
				p1: "更多最新精彩资讯，感受行业未来",
				newsTypes: [{
					title: "公司新闻",
					desc: '更多最新精彩资讯，感受企业魅力',
					url: "xinwen",
					type: 0,
				}, {
					title: "行业资讯",
					desc: '更多最新行业新闻，了解行业前景',
					url: "TradeNews",
					type: 1,
				}],
			};
		},
		created() {
			this.artcleID = this.$route.query.id;
			this.curNews = this.newsList.find(item => item.id == this.artcleID);
			if (this.curNews.type == 0) {
				this.active = 0
			} else {
				this.active = 1
			}
		},
		methods: {
			changeItem(id){
				this.artcleID = id;
				this.curNews = this.newsList.find(item => item.id == this.artcleID);
				if (this.curNews.type == 0) {
					this.active = 0
				} else {
					this.active = 1
				}
				this.$refs['childData'].getAllList();
			},
			NewsDetails(it) {
				this.$router.push({
					path: 'xinwen',
					query:{
						type: it.type
					}
				});
			},
		},
	};
</script>
<style scoped>
	.main_content {
		max-width: 677px;
		line-height: 1.75em;
	}

	.content_text {
		color: rgb(35, 31, 32);
		font-family: Optima-Regular, PingFangTC-light;
		font-size: 14px;
		text-indent: 2em;
		letter-spacing: 0.034em;
		visibility: visible;
		margin-bottom: 1em;
	}

	.content_title_one,
	.content_title_two {
		text-align: center;
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.content_title_three {
		margin-bottom: 1em;
		font-size: 16px;
		font-weight: bold;
	}

	.content_title_fourth {
		margin-bottom: 1em;
		font-size: 15px;
		font-weight: bold;
	}

	.content_title_three span {
		border-bottom: 8px solid aquamarine;
	}


	.content_title_two span {
		font-weight: bolder;
		font-size: 1.3em;
	}

	.content_title_one span {
		background-color: aquamarine;
		padding: 0.5em;
		border: 1px solid aquamarine;
		border-radius: 2.5em;
		font-size: 1.5em;
		color: #fff;
		font-weight: bolder;
	}

	.content_img {
		text-align: center;
	}

	.content_img img {
		/* width: 417px; */
		width: 100%;
	}

	.btn_cont {
		display: flex;
		padding-top: 10px;
		justify-content: center;
	}

	.btn_cont div {
		text-align: center;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 40px;
		margin-right: 18px;
		width: 185px;
		height: 40px;
		background: #f6f6f6;
		border-radius: 6px;
		cursor: pointer;
	}

	.active {
		color: #ffffff !important;
		background-color: #3b5369 !important;
		/* background: #23337f !important; */
	}

	/*==========================Reset_End===========================*/

	.youjiaopage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	/* 新闻详情 */
	.NewsDetails_cont {
		width: 1200px;
		margin: 0 auto;
	}

	.NewsDetails_cont_title>h1 {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2b2b2b;
		line-height: 33px;
		margin-top: 78px;
		text-align: center;
	}

	.NewsDetails_cont_title img {
		vertical-align: middle;
	}

	.NewsDetails_cont_title_span {
		text-align: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #a3a3a3;
		line-height: 30px;
		margin-top: 8px;
	}

	.NewsDetails_cont_text {
		/* text-align: center; */
		justify-content: center;
		display: grid;
		margin-top: 40px;
		border-top: 1px solid #eaeaea;
		padding-top: 40px;
	}

	.NewsDetails_cont_img {
		text-align: center;
		margin-bottom: 40px;
	}

	.NewsDetails_cont_btm {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm div {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #222222;
		line-height: 33px;
	}

	.NewsDetails_cont_btm p {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
	}

	.NewsDetails_cont_btm2 {
		display: flex;
		justify-content: space-between;
	}

	.NewsDetails_cont_btm2_1 {
		margin: 32px 0;
		padding: 31px 61px 31px 20px;
		width: 507px;
		height: 123px;
		background: #ffffff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
	}

	.NewsDetails_cont_btm2_1 div {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
		line-height: 25px;
	}

	.NewsDetails_cont_btm2_1 p {
		margin-top: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 28px;
	}
</style>