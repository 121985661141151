<template>
	<!-- star -->
	<div class="kejipage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<div class="main_type">
			<ul>
				<li v-for="item,index in chanpinList" :key="index" class="main_type_li" @click.stop="gochild(item)">
					<span>{{item.name}}</span>
					<ul v-if="item.children.length>0" class="second_type_ul">
						<li v-for="item2,index2 in item.children" :key="index2" class="second_type_li" @click.stop="gochild(item2)">
							<span>{{item2.name}}</span>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div style="width: 1200px;margin: 50px auto;padding: 150px 0;">
			<div v-if="ifFirst" class="first_body">
				<div class="first_img">
					<img :src="curObj.img" alt="">
				</div>
				<div class="first_desc">
					<p>{{ curObj.name }}</p>
					<p>&nbsp;&nbsp;&nbsp;&nbsp;{{ curObj.desc }}</p>
				</div>
			</div>
			<div v-else class="second_body">
				<div class="second_title">
					<P>{{ curObj.name }}</P>
				</div>
				<div class="second_desc">
					<div v-if="curObj.td" class="second_desc_one">
						<div style="color: #cfb68b;margin-right: 5px;font-size: 18px;">
							<span>产品特点:</span>
						</div>
						<div>
							<p v-for="item,index in curObj.td" :key="index">{{item.text}}</p>
						</div>
						<!-- <p>{{  }}</p> -->
					</div>
					<div v-if="curObj.ly" class="second_desc_two">
						<!-- <span>应用领域:</span><span>{{ curObj.ly }}</span> -->
						<div style="color: #cfb68b;margin-right: 5px;font-size: 18px;">
							<span>应用领域:</span>
						</div>
						<div>
							<p v-for="item,index in curObj.ly" :key="index">{{item.text}}</p>
						</div>
					</div>
					<div v-if="columns" class="second_desc_three">
						<ve-table :columns="columns" :table-data="tableData" :border-around="true" :border-x="true" :border-y="true" :cell-span-option="cellSpanOption"/>
					</div>
				</div>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";
	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			chanpinList() {
				return this.$store.state.chanpinList;
			},
		},
		data() {
			
			return {
				cellSpanOption: {
					bodyCellSpan: this.bodyCellSpan,
				},
				h1: "公司产品",
				Img1: require("../assets/img/keji/banner.png"),
				p1: "更多最新精彩资讯，感受企业魅力",
				curId: null,
				curObj: {},
				ifFirst: false,
				columns: [],
				tableData: [],
			};
		},
		created() {
			this.curId = this.$route.query.id;
			if (this.curId.includes('-')) {
				var arr = this.curId.split('-');
				var curArr = this.chanpinList.find(it => it.id == arr[0]).children;
				this.curObj = curArr.find(it => it.id == this.curId);
				this.columns = this.curObj.columns
				this.tableData = this.curObj.tableData
				this.ifFirst = false;
			} else {
				this.ifFirst = true;
				this.curObj = this.chanpinList.find(it => it.id == this.curId);
			}
		},
		methods: {
			gochild(nobj) {
				this.$router.push({ path: nobj.url, query: { id: nobj.id } }).then(() => {
					window.location.reload();
				});
			},
			bodyCellSpan({ column, rowIndex }) {
				// console.log(row);
				console.log(column,rowIndex);
				if (column.field === "GRADE") {
						if (rowIndex === 0) {
								return {
										rowspan: 28,
										colspan: 1,
								};
						}
						// does not need to be rendered
						else {
								return {
										rowspan: 0,
										colspan: 0,
								};
						}
				}
			},
		},
	};
</script>
<style>
	.ve-table-header-th {
		background-color: #fff;
		color: #cfb68b !important;
		font-weight: 600 !important;
	}
</style>
<style scoped>
	.kejipage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	.main_type>ul {
		display: flex;
		line-height: 70px;
		justify-content: space-around;
		color: #fff;
		font-size: 20px;
	}

	.main_type_li {
		padding: 0 20px;
		position: relative;
		z-index: 1000;
	}

	.main_type_li:hover {
		background-color: #000;
		color: #fff;
	}

	.second_type_li:hover {
		background-color: #fff;
		color: #eba631;
	}

	.main_type_li:hover .second_type_ul {
		display: block;
	}

	.second_type_ul {
		background-color: #000;
		color: #fff;
		position: absolute;
		display: none;
		left: -5px;
		z-index: 1000;
		font-size: 17px;
	}

	.second_type_li {
		/* display: inline-block; */
		line-height: 40px;
		white-space: nowrap;
		text-align: center;
	}

	.main_type {
		width: 100vw;
		background-color: #04111a;
		position: absolute;
		top: 533px;
		/* left: 15%; */
		opacity: 0.7;
		z-index: 1000;
	}

	.first_img>img {
		width: 400px;
		height: 274px;
	}

	.first_body {
		justify-content: space-around;
		display: flex;
		height: 100%;
		padding: 15px 20px;
		background-color: #fff;
	}

	.first_body>div {
		margin-top: 60px;
	}

	.first_desc :nth-child(1) {
		text-align: center;
		font-size: 35px;
		margin-bottom: 40px;
		font-weight: bolder;
		color: #cfb68b;
	}

	.first_desc :nth-child(2) {
		text-align: left;
		text-indent: 1em;
    line-height: 30px;
    letter-spacing: 0.034em;
    visibility: visible;
		font-size: 18px;
		/* font-weight: bolder; */
	}

	.first_desc {
		padding: 00px 30px;
	}

	.second_body {
		/* justify-content: space-around;
	display: flex; */
		margin: auto;
		width: 1400px;
		height: 100%;
		padding: 50px 100px;
		background-color: #fff;
	}

	.second_title>p {
		text-align: center;
		font-size: 25px;
		margin-bottom: 40px;
		/* font-weight: bolder; */
		color: #cfb68b;
	}

	.second_desc {
		font-size: 18px;
	}

	.second_desc_one,
	.second_desc_two {
		display: flex;
		margin-bottom: 10px;
	}

	/* .second_desc_one :nth-child(1) {
		
	} */

	/* .second_desc_two :nth-child(1) {
		color: #cfb68b;
		margin-right: 5px;
		font-size: 18px;
	} */

	.second_desc_three {
		margin-top: 30px;
		z-index: -1;
	}
</style>