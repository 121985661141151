<template>
	<!-- star -->
	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
		<div class="yingyun_page">
			<div class="yy_item" v-for="item,index in yyList" :key="index">
				<img :src="item.img" alt="">
				<p>{{ item.name }}</p>
			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "产品应用",
			Img1: require("../assets/img/yingyong/banner.png"),
			yyList:[{
				name: '仪器仪表 ',
				img: require('../assets/img/yingyong/yibiao.png')
			},{
				name: '工业智能化',
				img: require('../assets/img/yingyong/gongye.png')
			},{
				name: '高性能电源及模组',
				img: require('../assets/img/yingyong/dianyuanicon.png')
			},{
				name: '自动化设备',
				img: require('../assets/img/yingyong/zidong.png')
			},{
				name: '物联网及数据传输',
				img: require('../assets/img/yingyong/wulian.png')
			},{
				name: '电力',
				img: require('../assets/img/yingyong/dianli.png')
			},{
				name: '通讯系统',
				img: require('../assets/img/yingyong/satellite.png')
			},{
				name: '高速铁路',
				img: require('../assets/img/yingyong/huoche.png')
			},{
				name: '航空航天',
				img: require('../assets/img/yingyong/hangkong.png')
			},{
				name: '汽车电子',
				img: require('../assets/img/yingyong/qiche.png')
			}]
		}
	},
	methods: {},
};
</script>
<style scoped>
.yingyun_page{
	width: 1200px;
	margin: auto;
	display: flex;
	flex-wrap:wrap;
	margin-bottom: 50px;
}
.yy_item{
	width:25%;
	vertical-align: middle;
	display: table-cell;
	text-align: center;
}
.yy_item >img{
	margin: 20px auto;
	width: 85px;
  height: 85px;
}
</style>
