<template>
	<div>
		<!-- ---------- -->

		<div class="footer_warp">
			<div class="footer_cont">
				<!-- 上部 -->
				<div class="footer_cont_l">
					<!--上部 左 -->
					<div class="footer_cont_l_div">
						<div class="footer_cont_l_div_item">
							<p class="footer_cont_l_title">企业概况</p>
							<ul class="footer_cont_l_ul">
								<li v-for="(item, index) in arrList" :key="index" @click="toGuid(item.url, item.id)">
									{{ item.title }}
								</li>
								<!-- <li>企业荣誉</li> -->
							</ul>
						</div>
						<div class="footer_cont_l_div_item">
							<p class="footer_cont_l_title">公司产品</p>
							<ul class="footer_cont_l_ul">
								<li v-for="(item, index) in arrList2" :key="index" @click="toPages(item)">
									{{ item.title }}
								</li>
							</ul>
						</div>
						<div class="footer_cont_l_div_item">
							<p class="footer_cont_l_title">企业新闻</p>
							<ul class="footer_cont_l_ul">
								<li v-for="(item, index) in arrList3" :key="index" @click="toNews(item.url)">
									{{ item.title }}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- top 中 -->
				<div class="footer_cont_c">
					<div class="footer_img">
						<img src="https://img.sosozhaofang.com/SKVMICRO/nlogo.png" alt="" />
					</div>
					<div>
						<p class="address">
							公司总部地址：珠海市高新区唐家湾<br />
							邮箱：hr@skvmicro.com
						</p>
					</div>
				</div>
			</div>
			<!-- 下部 -->
			<div class="footer_warp_btm">
				<p>
					Copyright
					©&ensp;&ensp;SKV MICRO Co.Ltd&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;网站备案号&nbsp;&nbsp;<a
						href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024180273号</a>
				</p>
			</div>
		</div>

		<!-- --------- -->
	</div>
</template>

<script>
	export default {
		name: "Footer",
		data() {
			return {
				arrList: [
					{
						title: "关于企业",
						url: "/jituangaikuo",
						id: "about",
					},
					{
						title: "企业实力",
						url: "/jituangaikuo",
						id: "CultureInfo",
					},
				],
				arrList2: [
					{
						title: "运放芯片",
						url: "/keji",
						id: 1
					},
					{
						title: "电源芯片",
						url: "/keji",
						id: 2
					},
					{
						title: "专用芯片",
						url: "/keji",
						id: 3
					},
					{
						title: "无线芯片",
						url: "/keji",
						id: 4
					},
				],
				arrList3: [
					{
						title: "公司新闻",
						url: "/xinwen",
					},
					{
						title: "行业资讯",
						url: "/xinwen/TradeNews",
					},
				],
			};
		},

		mounted() {
			let _this = this;
			_this.$nextTick(function () {
				window.addEventListener("scroll", _this.handleScroll);
			});
		},

		methods: {
			// 锚点跳转
			toGuid(path, id) {
				localStorage.setItem("toId", id);
				this.$router.push(path);
				//查找存储的锚点id
				let Id = localStorage.getItem("toId");
				let toElement = document.getElementById(Id);
				//锚点存在跳转
				if (Id) {
					toElement.scrollIntoView();
					localStorage.setItem("toId", "");
				}
			},

			toPages(nobj) {
				console.log(nobj)
				this.$router.push({ path: nobj.url, query: { id: nobj.id } }).then(() => {
					window.location.reload();
				});
			},
			toNews(url) {
				this.$router.push(url);
			},
		},
	};
</script>
<style scoped>
	.footer_img img {
		/* width: 150px; */
		height: 70px;
	}

	/*去内外边距*/
	html,
	body {
		/* font-family: "微软雅黑", "宋体", Arail, Tabhoma; */
		text-align: left;
	}

	/*字体 文本居左*/
	ul {
		list-style: none;
	}

	/*去ul li 小点*/
	img {
		border: 0 none;
	}

	/*img IE6 兼容问题*/
	input,
	select,
	textarea {
		outline: 0;
	}

	/*去除点击蓝框*/
	textarea {
		resize: none;
		overflow: auto;
	}

	/*锁定多行文本框大小 与文字超出出现滚动条*/
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/*表格边框合并 边框间距0*/
	th,
	strong,
	var,
	em {
		font-weight: normal;
		font-style: normal;
	}

	/*特殊文字粗细 样式显示正常*/
	a {
		text-decoration: none;
	}

	/*a标签去下划线*/
	.clearfix:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	/*清浮动*/
	a {
		color: #000000;
	}

	/*==========================Reset_End===========================*/

	.footer_warp {
		width: 100vw;
		height: 388px;

		background: #1b2025;
	}

	.footer_cont {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}

	.footer_cont_l {
		font-size: 16px;
		font-weight: 500;
		color: #dddddd;
		line-height: 22px;
		padding-top: 77px;
		width: 45%;
	}

	.footer_cont_l_title {
		font-size: 18px;
		/* font-family: PingFangSC-Medium, PingFang SC; */
		font-weight: 500;
		color: #cfb68b;
		line-height: 22px;
		/* width: 64px; */
	}

	.footer_cont_l_ul {
		margin-top: 36px;
	}

	.footer_cont_l_ul li {
		font-size: 16px;
		color: #999999;
		margin-top: 10px;
		cursor: pointer;
	}

	.footer_cont_l_div {
		display: flex;
	}

	.footer_cont_l_div_item {
		margin-right: 72px;
	}

	.footer_cont_c {
		margin-top: 70px;
		margin-left: 180px;
		width: 55%;
	}

	.address {
		font-size: 18px;
		color: #ffffff;
		line-height: 28px;
		/* margin-top: 15px; */
	}

	.footer_cont_r {
		margin-top: 79px;
		margin-left: 70px;
	}

	.footer_cont_r p {
		text-align: center;
		font-size: 14px;
		color: #ffffff;
		line-height: 20px;
		margin-top: 17px;
	}

	.footer_cont_r_border {
		width: 111px;
		height: 109px;
		border: 1px solid #979797;
	}

	.footer_cont_r_border img {
		width: 95px;
		height: 95px;
		margin: 7px 8px;
	}

	.footer_warp_btm {
		width: 100vw;
		margin: 0 auto;
		height: 65px;
		margin-top: 56px;

		border-top: 1px solid #444444;
	}

	.footer_warp_btm p {
		font-size: 12px;
		text-align: center;
		color: #a3a3a3;
		line-height: 48px;
	}

	.footer_warp_btm p a {
		font-size: 12px;
		text-align: center;
		color: #a3a3a3;
		line-height: 48px;
	}
</style>