<!-- star -->
<template>
	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
		<!-- 行政运营 -->
		<div class="Administrative">
			<div class="Administrative_cont">
				<div class="Administrative_cont_bottom">
					<div>
						<h1>岗位职责：</h1>
						<p v-for="item,index in zzArr" :key="index">{{ item }}</p>
					</div>
					<div>
						<h1>岗位要求：</h1>
						<p v-for="item,index in yqArr" :key="index">{{ item }}</p>
					</div>
				</div>
			</div>
		</div>
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import banner2 from "../../components/banner.vue";
import nav2 from "../../components/nav.vue";
export default {
	name: "keji",
	components: {
		footer2,
		nav2,
		banner2,
	},
	data() {
		return {
			h1: "招聘信息",
      Img1: require("../../assets/img/zhaopinxinxi/zhaopin.png"),
      // p1: "更多最新精彩资讯，感受企业魅力",
			curId: null,
			curObj: {},
			zzArr: [],
			yqArr: []
		};
	},
	computed: {
		zhaopinList() {
			return this.$store.state.zhaopinList;
		},
	},
	created(){
		this.curId = this.$route.query.id;
		this.curObj =this.zhaopinList.find(it=>it.id==this.curId);
		this.h1 = this.curObj.title;
		var zz = this.curObj.zhize;
		if (zz) {
			this.zzArr = zz.split('；');
		}
		var yq = this.curObj.yaoqiu;
		if (yq) {
			this.yqArr = yq.split('；');
		}
	},
	methods: {
		
	},
};
</script>
<style scoped>

.Administrative_cont {
	width: 1200px;
	margin: 24px auto 80px auto;
}

.Administrative_cont_top {
	height: 217px;

	border-bottom: 1px solid #eaeaea;
	display: flex;
	justify-content: space-between;
}

.Administrative_cont_top_left > h1 {
	margin: 60px 0 32px 0;
}

.Administrative_cont_top_left_text {
	display: flex;
}

.Administrative_cont_top_left_text p {
	margin-bottom: 16px;
}

.Administrative_cont_top_left_text_l {
	margin-right: 120px;
}

.Administrative_cont_top_left_text_l p {
	font-size: 16px;
	font-weight: 500;
	color: #444444;
	line-height: 22px;
}

.Administrative_cont_top_right p {
	margin: 67px 0 50px 0;

	font-size: 14px;
	font-weight: 400;
	color: #999999;
	line-height: 20px;
}

.Administrative_cont_top_right button {
	cursor: pointer;
	width: 160px;
	height: 48px;
	background: #23337f;
	border-radius: 4px;

	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
	line-height: 22px;
}

.Administrative_cont_bottom {
	margin: 60px 0 52px 0;
}

.Administrative_cont_bottom h1 {
	font-size: 20px;
	font-weight: bolder;
	color: #444444;
	line-height: 28px;
}

.Administrative_cont_bottom p {
	font-size: 17px;
  color: #262626;
}

.Administrative_cont_bottom div {
	margin-top: 28px;
}
</style>
