<template>
  <div class="NewsDetails_cont_footer">
    <!-- 使用三目运算符 判断按钮是否可以点击 当变量为true 不可以点击  -->
    <div @click="last" :class="lastNoShow ? 'noClick' : 'btn'">
      <img src="../assets/img/newsDetail/公共-更多2(1).png" alt="" style="margin-right: 10px;" />
      <span>上一篇 {{ lastTitle }}</span>
    </div>
    <!-- 使用三目运算符判断 按钮是否可以点击 -->
    <div @click="next" :class="nextNoShow ? 'noClick' : 'btn'">
      <span>下一篇 {{ nextTitle }}</span>
      <img src="../assets/img/newsDetail/公共-更多2.png" alt="" style="margin-left: 10px;" />
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        // 获取的数组，就是文章列表
        arrList: [],
        //上一个显示的标题内容
        lastTitle: "",
        //下一个显示的标题内容
        nextTitle: "",
        //下一个的ID 用于跳转页面
        nextId: "",
        //上一个的ID 用于跳转页面
        lastId: "",
        // 控制按钮是否可以点击
        lastNoShow: "",
        nextNoShow: "",
      };
    },
    //页面创建前执行一下这个函数
    created() {
      this.getAllList();
    },
    methods: {
      getAllList() {
        // 我这是把这个给变成子组件了 获取你文章的ID 用来进行匹配当前的页面
        //   父组件写了什么可以看文章下面
        let id = this.$parent.artcleID;
        let allList = this.$parent.newsList;
        let type = this.$parent.active
        this.arrList = allList.filter(it=>it.type == type)
        var index = this.arrList.findIndex(it=>it.id == id);
        if (index >= this.arrList.length-1) {
          this.nextTitle = "无";
          this.nextNoShow = true;
        }else{
          // 改变你下一篇标题显示的内容 为 下个index里显示的标题
          this.nextTitle = this.arrList[index + 1].title;
          // 把nextID 的值也变成下个index 里 id的值 为了进行页面的跳转
          this.nextId = this.arrList[index + 1].id;
          // 把下一篇变成可以点击的状态,因为这个else里 下一篇还有内容
          this.nextNoShow = false;
        }
        if (index == 0) {
          this.lastTitle = "无";
          this.lastNoShow = true;
        }else{
          this.lastTitle = this.arrList[index - 1].title;
          this.lastId = this.arrList[index - 1].id;
          this.lastNoShow = false;
        }
      },
      //点击上一篇文章时;
      last() {
        this.$emit('changeItem',this.lastId)
        this.$router.push({
          // 拼接 lastID 和我们定义的这个地址，就能实现跳转了
          path: "/newsxiangqing?id=" + this.lastId,
        });
      },
      //点击下一篇文章时
      next() {
        this.$emit('changeItem',this.nextId)
        this.$router.push({
          path: "/newsxiangqing?id=" + this.nextId,
        });
      },
    },
  };
</script>
<style scoped>
  .NewsDetails_cont_footer {
    padding: 0 40px;
    line-height: 68px;
    width: 1120px;
    height: 68px;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .noClick {
    visibility: hidden;
    /* display: none; */
    /* 不可点击 */
    /* pointer-events: none;
    display: flex;
    align-items: center; */
  }
</style>