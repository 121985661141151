<template>
	<div>
		<div class="wrap">
			<div class="logo">
				<img :src="logoimg" />
			</div>
			<div class="nav">
				<ul class="navWrap_center_ul">
					<li class="wp_subtop" v-for="(item, index) in navList" :key="index" @click="funyuming(item)">
						<span>{{item.title}}</span>
						<ul v-if="item.type==1 && curRouteName != 'keji'" class="navWrap_center_ul_2">
							<li v-for="item2,index2 in item.children" :key="index2" class="wp_subtop_2" @click.stop="gochild(item2)">
								<span>{{item2.name}}</span>
								<ul v-if="item2.children.length>0" class="navWrap_center_ul_3">
									<li v-for="item3,index3 in item2.children" :key="index3" class="wp_subtop_3"
										@click.stop="gochild(item3)">
										<span>{{item3.name}}</span>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Nav",
		data() {
			return {
				navList: [
					{ title: "首页", url: "/", type: 0 },
					{ title: "产品", url: "/keji", type: 1, children: [] },
					{ title: "应用", url: "/yingyong", type: 0 },
					{ title: "招聘", url: "/zhaopinxinxi", type: 0 },
					{ title: "关于公司", url: "/jituangaikuo", type: 0 }
				],
				logoimg: 'https://img.sosozhaofang.com/SKVMICRO/nlogo.png',// require("../assets/logo.png"),
				curRouteName: '',
			};
		},
		computed: {
			chanpinList() {
				return this.$store.state.chanpinList;
			},
		},
		created() {
			this.navList[1].children = this.chanpinList;
			console.log();
			this.curRouteName = this.$route.name
		},
		methods: {
			gochild(nobj) {
				this.$router.push({ path: nobj.url, query: { id: nobj.id } }).then(() => {
					window.location.reload();
				});
			},
			funyuming(obj) {
				if (obj.type != 1) {
					this.$router.push(obj.url);
				} else {
					var nobj = obj.children[0];
					this.gochild(nobj)
				}
			},
			funRouter() {
				this.$router.push("/keji");
			},
		},
	};
</script>
<style scoped>
	/*==========================Reset_End===========================*/

	.youjiaopage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	.wrap {
		display: flex;
		justify-content: space-around;
		width: 100vw;
		height: 101px;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
	}

	.wrap>.logo>img {
		margin: 20px;
		height: 70px;
	}

	.nav {
		padding-top: 30px;
	}

	.navWrap_center_ul {
		margin-right: 121px;
	}

	.wp_subtop {
		position: relative;
	}

	.navWrap_center_ul_2 {
		position: absolute;
		display: none;
		background-color: #fff;
		width: 125px;
		z-index: 1000;
		top: 40px;
		padding-top: 15px;
		left: -40px;
	}

	.navWrap_center_ul_3 {
		position: absolute;
		display: none;
		/* padding: 0 5px; */
		background-color: #fff;
		;
		left: 120px;
		top: 0px;
		z-index: 1000;
	}

	.wp_subtop_3 {
		width: 100%;
		padding: 5px 0px;
		display: inline-block;
		white-space: nowrap;
		color: #000000;

	}

	.navWrap_center_ul>li {
		display: inline-block;
		line-height: 98px;
		margin-left: 50px;
		color: #222222;
	}

	.wp_subtop:hover>ul {
		display: block;
	}

	.wp_subtop_2:hover>ul {
		display: block;
	}

	.wp_subtop_2 {
		font-size: 15px;
		color: #000000;
		text-align: center;
		padding: 2px 5px;
		line-height: 40px;
		position: relative;
	}

	.wp_subtop_2:hover {
		cursor: pointer;
		color: #fff;
		background-color: #003b5d;
	}

	.wp_subtop_3:hover {
		cursor: pointer;
		color: #fff;
		background-color: #003b5d;
	}

	.nav>ul>li {
		height: 46px;
		float: left;
		display: flex;
		list-style-type: none;
		color: rgba(68, 68, 68, 1);
		font-size: 20px;
		margin-right: 20px;
		cursor: pointer;
	}

	.nav ul li span {
		line-height: 52px;
	}
</style>