<template>
	<!-- star -->

	<div class="youjiaopage_wrap">
		<nav2></nav2>
		<banner2 :bannerH="activeItem.title" :bannerP="activeItem.desc" :bannerImg="Img1"></banner2>
		<!-- 面包屑 -->
		<!-- <div class="Crumbs">
			<div>当前位置：首页><span>{{activeItem.title}}</span></div>
		</div> -->
		<!-- 按钮板块 -->
		<div class="btn">
			<div class="btn_cont">
				<div :class="activeItem.url === item.url ? 'active' : ''" v-for="(item, index) in newsTypes" :key="index"
					@click="NewsDetails(item)">
					{{ item.title }}
				</div>
			</div>
		</div>
		<div class="item_cont">
			<div v-for="item,index in curNews" :key="index" class="item_cont1" @click="newsArticle(item)">
				<div>
					<img :src="item.headImage" alt="">
				</div>
				<div class="item_cont1_text">
					<p class="item_cont1_text_top">{{ item.title }}</p>
					<p class="item_cont1_text_top_time">{{ item.time }}</p>
					<p class="item_cont1_text_top_content">{{ item.conntentText }}</p>
				</div>
			</div>
		</div>
		<!-- 新闻内容 -->
		<!--<router-view></router-view>-->
		<!-- 翻页列表 -->
		<!-- <div class="Pages">
			<div class="Pages_cont">
				<ul>
					<li>《</li>
					<li>1</li>
					<li>2</li>
					<li>3</li>
					<li>4</li>
					<li>5</li>
					<li>6</li>
					<li>...</li>
					<li>20</li>
					<li>》</li>
				</ul>
			</div>
		</div> -->
		<footer2></footer2>
		<!-- end -->
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	import banner2 from "../components/banner.vue";
	import nav2 from "../components/nav.vue";

	export default {
		name: "keji",
		components: {
			footer2,
			nav2,
			banner2,
		},
		computed: {
			newsList() {
				return this.$store.state.newsList;
			},
		},
		data() {
			return {
				activeItem: {
					title: "公司新闻",
					desc: '更多最新精彩资讯，感受企业魅力',
					url: "GroupNews",
					type: 0,
				},
				newsTypes: [{
					title: "公司新闻",
					desc: '更多最新精彩资讯，感受企业魅力',
					url: "GroupNews",
					type: 0,
				}, {
					title: "行业资讯",
					desc: '更多最新行业新闻，了解行业前景',
					url: "TradeNews",
					type: 1,
				}],
				curNews: [],
				Img1: require("../assets/img/news/banner.png"),//require("../assets/img/keji/矿山banner1.png"),
			};
		},
		// watch: {
		// 	$route: {
		// 		// 值发生变化时的回调函数
		// 		handler(now) {
		// 			//..... // now: 变化之后的数据
		// 			// old: 变化之前的数据
		// 			let url = now.name;
		// 			if (url.includes("TradeNews")) {
		// 				this.active = "TradeNews";
		// 			} else if (url.includes("GroupNews")) {
		// 				this.active = "GroupNews";
		// 			}
		// 			this.activeItem = this.newsList.find(it => it.url == this.active);

		// 		},
		// 		immediate: true, //立即执行
		// 	},
		// },
		created(){
			// console.log()
			var type = this.$route.query.type
			this.activeItem = this.newsTypes.find(item=>item.type == type);
			this.getCurNews();
		},
		methods: {
			NewsDetails(it) {
				this.activeItem = it;
				this.getCurNews()
			},
			getCurNews(){
				this.curNews = this.newsList.filter(item=>item.type == this.activeItem.type);
				this.curNews.forEach(ele => {
					if (ele.content && ele.content.length > 0) {
						ele.conntentText = ele.content.find(it=>it.type == 0).text
					}
				});
			},
			newsArticle(item) {
				this.$router.push("/newsxiangqing?id=" + item.id);
			},
		},
	};
</script>
<style scoped>


	/*==========================Reset_End===========================*/

	.youjiaopage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	/* 面包屑 */
	.Crumbs {
		width: 1200px;
		height: 46px;
		margin: 0 auto;
	}

	.Crumbs {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 17px;
	}

	.Crumbs div {
		line-height: 46px;
	}

	.Crumbs span {
		color: #000000;
	}

	/* 按钮 */

	.btn_cont {
		display: flex;
		padding-top: 10px;
		justify-content: center;
	}

	.btn_cont div {
		text-align: center;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #444444;
		line-height: 40px;
		margin-right: 18px;
		width: 185px;
		height: 40px;
		background: #f6f6f6;
		border-radius: 6px;
		cursor: pointer;
	}

	.active {
		color: #ffffff !important;
		background-color: #3b5369 !important;
		/* background: #23337f !important; */
	}

	/* 新闻列表 */
	.item_cont {
		width: 1200px;
		margin: 0 auto;
		margin-top: 40px;
	}

	.item_cont1 {
		display: flex;
		width: 1200px;
		height: 252px;
		margin-bottom: 20px;
		background: #ffffff;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
	}

	.item_cont1 img {
		margin: 32px 48px 32px 32px;
		width: 300px;
		height: 188px;
		border: dashed;
	}

	.item_cont1_text {
		margin: 32px 48px 32px 0px;
		width: 800px;
	}

	.item_cont1_text_top {
		margin: 0px 0 10px 0;
    /* width: 732px; */
    font-size: 28px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #cfb68b;
	}
	.item_cont1_text_top_time{
		color: #999999;
		padding-bottom: 5px;
    border-bottom: 1px solid #eaeaea;
	}
	.item_cont1_text_top_content{
		text-indent: 2em;
		letter-spacing: 0.034em;
		line-height: 1.75em;
		margin-top: 10px;
		overflow: hidden;
		/* height: 100px; */
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis; 
		-webkit-line-clamp: 4;
		/* white-space: nowrap; */
		/* text-overflow: ellipsis; */
	}
	/* 翻页 */
	.Pages_cont {
		margin: 40px 0;
		margin-left: 715px;
	}

	.Pages_cont ul {
		display: flex;
	}

	.Pages_cont ul li {
		text-align: center;
		line-height: 40px;
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}

	.Pages_cont ul li:hover {
		background: #23337f;
	}
</style>