import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import jituangaikuo from "../views/jituangaikuo.vue";
import keji from "../views/keji.vue";
import yingyong from "../views/yingyong.vue";
import news from "../views/news.vue";
import GroupNews from "../views/News/GroupNews.vue";
import TradeNews from "../views/News/TradeNews.vue";
import newsDetail from "../views/NewsDetals/newsxiangqing.vue";
// import TradeNews0 from "../views/NewsDetals/TradeNews0.vue";
// import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
import zhaopinxinxi from "../views/zhaopinxinxi.vue";
import zhaopinxiangqing from "../views/Recruitment-details/zhaopinxiangqing.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "首页",
		},
	},
	{
		path: "/jituangaikuo",
		name: "jituangaikuo",
		component: jituangaikuo,
		meta: {
			title: "集团概况",
		},
	},
	{
		path: "/keji",
		name: "keji",
		component: keji,
		meta: {
			title: "产品",
		},
	},
	{
		path: "/yingyong",
		name: "yingyong",
		component: yingyong,
		meta: {
			title: "应用",
		},
	},
	{
		path: "/xinwen",
		name: "xinwen",
		component: news,
		children: [
			{
				path: "",
				component: GroupNews,
				name: "GroupNews",
				meta: {
					title: "公司新闻",
				},
			},
			{
				path: "TradeNews",
				name: "TradeNews",
				component: TradeNews,
				meta: {
					title: "行业资讯",
				},
			},
		],
		meta: {
			title: "集团新闻",
		},
	},
	{
		path: "/newsxiangqing",
		name: "newsDetal",
		component: newsDetail,
		meta: {
			title: "新闻详情",
		},
	},
	// {
	// 	path: "/TradeNews0",
	// 	name: "TradeNews0",
	// 	component: TradeNews0,
	// 	meta: {
	// 		title: "行业资讯0",
	// 	},
	// },
	// {
	// 	path: "/TradeNews1",
	// 	name: "TradeNews1",
	// 	component: TradeNews1,
	// 	meta: {
	// 		title: "行业资讯1",
	// 	},
	// },
	{
		path: "/zhaopinxinxi",
		name: "zhaopinxinxi",
		component: zhaopinxinxi,
		meta: {
			title: "招聘信息",
		},
	},
	{
		path: "/zhaopinxiangqing",
		name: "zhaopinxiangqing",
		component: zhaopinxiangqing,
		meta: {
			title: "招聘详情",
		},
	},
];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
