<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <div class="zhaopin_body">
      <div>欢迎加入我们</div>
      <div>--------</div>
      <div>工作地点： 北京、南京、珠海、成都、西安</div>
      <div>公司根据个人意愿定制发展空间，激励机制多样（如年终奖、绩效奖、 未来股权激励等），五险一金俱全，待遇具有竞争力。</div>
      <div>电邮：hr@skvmicro.com, 电话：+86-13322877139。</div>
    </div>
    <!-- 招聘信息 -->
    <div class="Recruitment">
      <div class="Recruitment_cont">
        <div class="Recruitment_cont_item1" v-for="(item, index) in zhaopinList" :key="index">
          <div class="Recruitment_cont_img">
            <img :src="item.img" alt="">
          </div>
          <div>
            <div class="gangwei" @click="onurl(item)">{{item.title}}</div>
            <div>
              <div style="height: 74px;overflow: hidden;">{{item.zhize}}</div>
              <div style="margin-top: 5px;color: grey;">{{item.time}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 翻页 -->
    <!-- <Pages></Pages> -->
    <footer2></footer2>
    <!-- end -->
  </div>
</template>
<script>
  import footer2 from "../components/footer.vue";
  import banner2 from "../components/banner.vue";
  // import Pages from "../components/recruitInfoPages.vue";
  import nav2 from "../components/nav.vue";
  export default {
    name: "zhaopinxinxi",
    components: {
      footer2,
      nav2,
      banner2,
      // Pages,
    },
    data() {
      return {
        h1: "招聘信息",
        Img1: require("../assets/img/zhaopinxinxi/zhaopin.png"),
        // p1: "更多最新精彩资讯，感受企业魅力",
      };
    },
    computed: {
      zhaopinList() {
        return this.$store.state.zhaopinList;
      },
    },
    methods: {
      onurl(nobj) {
        this.$router.push({ path: 'zhaopinxiangqing', query: { id: nobj.id } });
      },
    },
  };
</script>
<style scoped>
  /* 招聘信息 */
  .Recruitment_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    width: 1200px;
    /* height: 915px; */
    margin: 0 auto;
    margin-top: 61px;
  }

  .Recruitment_cont_item1 {
    padding: 24px 32px;
    width: 516px;
    background: #fff;
    display: flex;
    margin-bottom: 25px;

    /* -webkit-transform: perspective(1px) translateZ(0); */
    /* transform: perspective(1px) translateZ(0); */
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0); */
    /* -webkit-transition-duration: 0.3s; */
    transition-duration: 0.3s;
    /* -webkit-transition-property: box-shadow, transform; */
    /* transition-property: box-shadow, transform; */
  }

  .Recruitment_cont_item1:hover {
    box-shadow: 10px 10px 10px 5px rgba(0, 0, 0, 0.5);
    /* -webkit-transform: scale(1.1); */
    transform: scale(1.1);
    /* box-shadow: 5px 5px 9px 5px rgba(0, 0, 0, 0.1); */
  }


  .zhaopin_body {
    margin-top: 30px;
    text-align: center;
  }

  .zhaopin_body :nth-child(1) {
    font-size: 30px;
    /* font-weight: bolder; */
    color: #cfb68b;
    /* border-bottom: #23337f 2px solid; */
  }

  .zhaopin_body :nth-child(2) {
    font-size: 25px;
    font-weight: bolder;
    color: #cfb68b;
  }

  .zhaopin_body :nth-child(4) {
    margin-top: 15px;
    font-size: 20px;
    /* font-weight: bold; */
  }

  .zhaopin_body :nth-child(5) {
    margin-top: 15px;
    font-size: 20px;
    font-weight: bold;
  }

  .Recruitment_cont_img {
    display: flex;
    align-items: center;
  }

  .Recruitment_cont_img>img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }

  .gangwei:hover {
    color: #37489b;
  }

  .gangwei {
    font-size: 18px;
    color: #cfb68b;
    font-weight: bolder;
    text-wrap: nowrap;
    margin-bottom: 15px;
    cursor: pointer;
  }
</style>