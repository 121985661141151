<template>
	<!-- star -->
	<div class="homepage_wrap">
		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- banner -->
			<div class="banner_nav_bg">
				<img src="https://img.sosozhaofang.com/SKVMICRO/bg1.png" alt="" />
			</div>
			<!-- 导航栏 -->
			<div class="navWrap">
				<div class="navWrap_center">
					<div class="navWrap_center_img">
						<img src="https://img.sosozhaofang.com/SKVMICRO/nlogo.png" />
					</div>
					<ul class="navWrap_center_ul">
						<li class="wp_subtop" v-for="(item, index) in navList" :key="index" @click="funyuming(item)">
							<span>{{item.title}}</span>
							<ul v-if="item.type==1" class="navWrap_center_ul_2">
								<li v-for="item2,index2 in item.children" :key="index2" class="wp_subtop_2"
									@click.stop="gochild(item2)">
									<span>{{item2.name}}</span>
									<ul v-if="item2.children.length>0" class="navWrap_center_ul_3">
										<li v-for="item3,index3 in item2.children" :key="index3" class="wp_subtop_3"
											@click.stop="gochild(item3)">
											<div>{{item3.name}}</div>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<!-- title -->
			<div class="banner_title_wrap">
				<div class="banner_title_center">
					<p>{{ title }}</p>
					<div>本土原创高性能芯片</div>
				</div>
			</div>
		</div>

		<!-- 公司产品 -->
		<div class="GroupIndustry">
			<div class="GroupIndustry_cont">
				<!-- 左 -->
				<div class="GroupIndustry_cont_left">
					<!-- title -->
					<div class="GroupIndustry_cont_left_title">
						<p>公司产品</p>
					</div>
					<!-- img -->
					<div class="GroupIndustry_cont_left_img_f">
						<div v-for="(item, index) in typeList" :key="index" class="GroupIndustry_cont_left_img">
							<img :src="item.jituanimgyinying" class="GroupIndustry_cont_left_img2" />
							<div class="GroupIndustry_cont_left_text">
								<span>{{ item.jituanspan }}</span>
								<p>{{ item.jituanp }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 最新资讯 -->
		<div class="news">
			<div class="news_cont">
				<!-- left -->
				<div class="news_cont_left">
					<p>最新资讯</p>
					<div class="news_bg" @click="newsArticle(firstNews)">
						<div class="news_cont_left_img">
							<img :src="firstNews.headImage" alt="" />
						</div>
						<div class="news_cont_left_text">
							<h1>{{firstNews.title}}</h1>
							<p>{{firstNews.time}}</p>
							<div style="width: 100%;height: 10px;"></div>
							<p class="news_content">{{firstNews.conntentText}}</p>
						</div>
					</div>
				</div>
				<div class="news_cont_right">
					<div class="news_cont_right_ul">
						<ul>
							<router-link to="/xinwen?type=0">
								<li>公司新闻</li>
							</router-link>
							<span>|</span>
							<router-link to="/xinwen?type=1">
								<li>行业资讯</li>
							</router-link>
						</ul>
					</div>
					<div class="news_cont_right_text">
						<div class="news_cont_right_text_item news_cont_right_text_hr" v-for="(item, index) in newList" :key="index"
							@click="newsArticle(item)">
							<h1>{{ item.title }}</h1>
							<p>{{ item.time }}</p>
							<p class="news_content">{{ item.conntentText }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 关于企业 -->
		<div class="AboutEnterprises">
			<div class="AboutEnterprises_cont">
				<!-- top -->
				<div class="AboutEnterprises_cont_top">
					<!-- top_left -->
					<div class="AboutEnterprises_cont_top_left">
						<h1>关于企业</h1>
						<p>
							SKV
							MICRO是一家本土原创高性能芯片设计公司。基于过去20多年深耕国内消费及通讯芯片市场所积累的研发经验，在充分吸收国外先进芯片设计技术和设计方法的前提下，公司的核心主创人员立足于国内现有的商业化芯片生产工艺，在电路设计结构，电路设计方法和芯片校准流程等技术要素上充分创新，在事关国计民生核心工业需求的高性能、高精度模拟芯片领域实现逐步突破。天行微电的芯片产品，涵盖了精密信号链、集成模拟前端、高精度模拟数字转换、精密传感器调理以及硅基射频毫米波，高性能数模混合信号芯片及无线通讯等。
						</p>
						<router-link to="/jituangaikuo">
							<div class="AboutEnterprises_cont_top_left_btn">
								<span>了解更多&nbsp;>>></span>
							</div>
						</router-link>
					</div>
					<div class="AboutEnterprises_cont_top_right">
						<img src="../assets/img/guanyu/guanyu.png" alt="" />
						<div></div>
					</div>
				</div>

			</div>
		</div>
		<footer2></footer2>
	</div>
</template>

<script>
	import footer2 from "../components/footer.vue";
	export default {
		title: "index",
		components: {
			footer2,
		},
		data() {
			return {
				navList: [
					{ title: "首页", url: "/", type: 0 },
					{ title: "产品", url: "/keji", type: 1, children: this.chanpinList },
					{ title: "应用", url: "/yingyong", type: 0 },
					{ title: "招聘", url: "/zhaopinxinxi", type: 0 },
					{ title: "关于公司", url: "/jituangaikuo", type: 0 }
				],
				typeList: [{
					jituanimgyinying: require("../assets/img/xinpian/yunfang.png"),
					jituanspan: "运放芯片",
				}, {
					jituanimgyinying: require("../assets/img/xinpian/dianyuan.png"),
					jituanspan: "电源芯片",
				}, {
					jituanimgyinying: require("../assets/img/xinpian/zhuanyong.png"),
					jituanspan: "专用芯片",
				}, {
					jituanimgyinying: require("../assets/img/xinpian/wuxian.png"),
					jituanspan: "无线芯片",
				}],
				logoList: [
				],
				newList: [],
				firstNews: {},
			};
		},
		computed: {
			title() {
				return this.$store.state.title;
			},
			chanpinList() {
				return this.$store.state.chanpinList;
			},
			newsList() {
				return this.$store.state.newsList;
			},
		},
		created() {
			this.navList[1].children = this.chanpinList;
			this.firstNews = this.newsList[0];
			this.firstNews.conntentText = this.firstNews.content.find(it=>it.type == 0).text
			this.newList = this.newsList.slice(1, 4);
			this.newList.forEach(ele => {
				if (ele.content && ele.content.length > 0) {
					ele.conntentText = ele.content.find(it=>it.type == 0).text
				}
			});
		},
		methods: {
			gochild(nobj) {
				this.$router.push({ path: nobj.url, query: { id: nobj.id } });
			},
			funyuming(obj) {
				if (obj.type != 1) {
					this.$router.push(obj.url);
				} else {
					var nobj = obj.children[0];
					this.$router.push({ path: nobj.url, query: { id: nobj.id } });
				}
			},
			newsArticle(item) {
				this.$router.push("/newsxiangqing?id=" + item.id);
			},
			funRouter() {
				this.$router.push("/keji");
			},
		},
	};
</script>
<style scoped>
	/*==========================Reset_End===========================*/

	.homepage_wrap {
		width: 100vw;
		margin: 0 auto;
		height: 1000px;
	}

	.banner_nav {
		width: 100vw;
		height: 700px;
		position: relative;
	}

	.banner_nav_bg {
		/* 文本居顶部对齐 */
		height: 100%;
	}

	.banner_nav_bg img {
		/* 文本居顶部对齐 */
		height: 100%;
		width: 100vw;
		vertical-align: top;
	}

	/* 导航部分 */

	.navWrap {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		/*  */
		padding-top: 40px;
	}

	.navWrap_center {
		width: 1340px;
		height: 90px;
		margin: 0 auto;
		background: #fff;
		border-radius: 51px;
		/*  */
		display: flex;
		justify-content: space-between;
	}

	.navWrap_center_img {
		margin: 10px 0 0 45px;
	}

	.navWrap_center_img img {
		/* width: 180px; */
		height: 70px;
	}

	.navWrap_center_ul {
		margin-right: 121px;
	}

	.navWrap_center_ul_2 {
		position: absolute;
		display: none;
		background-color: #fff;
		left: -30px;
		width: 125px;
		z-index: 1000;
	}

	.navWrap_center_ul_3 {
		position: absolute;
		display: none;
		padding: 0 5px;
		background-color: #fff;
		left: 125px;
		top: 0px;
		z-index: 1000;
	}

	.wp_subtop_3 {
		width: 100%;
		/* padding: 5px 0px; */
		display: inline-block;
		white-space: nowrap;
		color: #000000;
		position: relative;
	}


	.navWrap_center_ul>li {
		display: inline-block;
		position: relative;
		line-height: 90px;
		margin-left: 50px;
		font-size: 20px;
		color: #222222;
	}

	.navWrap_center_ul>li:hover {
		cursor: pointer;
		color: rgba(35, 51, 127, 1);
		border-bottom: 2px solid #314359;
	}

	.wp_subtop:hover>ul {
		display: block;
	}

	.wp_subtop_2:hover>ul {
		display: block;
	}

	.wp_subtop_2 {
		color: #000000;
		text-align: center;
		font-size: 15px !important;
		line-height: 45px;

	}

	.wp_subtop_2:hover {
		cursor: pointer;
		color: #fff;
		background-color: #003b5d;
	}

	.wp_subtop_3:hover {
		cursor: pointer;
		color: #fff;
		background-color: #003b5d;
	}

	/* title */

	.banner_title_wrap {
		width: 100%;
		position: absolute;
		bottom: 290px;
		/* border: 1px solid yellow; */
	}

	.banner_title_center {
		text-align: center;
		margin: 0 auto;
		font-size: 80px;
		font-weight: bold;
		color: #cfb68b;
		line-height: 110px;
		letter-spacing: 1px;
	}

	.banner_title_center div {
		padding-top: 14px;
		font-size: 25px;
		line-height: 24px;
		letter-spacing: 4px;
	}

	.banner_title_center div span {
		margin: 0 18px;
	}

	/* news */
	.news {
		width: 100vw;
		height: 801px;
		background: #f8f8f8;
	}

	.news_cont {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.news_cont_left {
		margin-top: 89px;
	}

	.news_cont_left p {
		font-size: 25px;
		font-weight: 600;
		color: #444444;
		/* line-height: 50px; */
	}

	.news_cont_left_img img {
		width: 588px;
		height: 353px;
		margin-top: 41px;
	}

	.news_cont_left_text {
		/* padding-top: 27px; */
		padding: 30px;
		/* padding-bottom: 20px; */
		width: 588px;
		background-color: #ffffff;
	}

	.news_cont_left_text h1 {
		font-size: 20px;
		font-weight: 800;
		color: #cfb68b;
		margin-bottom: 10px;
	}

	.news_content{
		display: -webkit-box;
    text-indent: 2em;
    overflow: hidden;
    line-height: 1.75em;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
	}

	.news_cont_left_text p {
		font-size: 16px;
		font-weight: 400;
		color: #8f8f8f;
		/* line-height: 20px; */
	}

	.news_cont_right {
		width: 588px;
		margin-top: 103px;
	}

	.news_cont_right_ul ul {
		color: #b3b3b3;
		float: right;
		display: flex;
	}

	.news_cont_right_ul ul a {
		font-size: 17px;
		/* font-weight: 400; */
		color: #b3b3b3;
		line-height: 20px;
		float: right;
		display: flex;
	}

	.news_cont_right_ul ul li:hover {
		color: #23337f;
	}

	.news_cont_right_ul ul span {
		margin: 0 23px 0 24px;
	}

	.news_cont_right_text {
		width: 588px;
		height: 535px;
		background: #ffffff;
		margin-top: 68px;
	}

	.news_cont_right_text_item {
		padding: 30px;
		cursor: pointer;
	}

	.news_bg {
		cursor: pointer;
	}

	.news_cont_right_text_item h1 {
		font-size: 20px;
		font-weight: 800;
		color: #cfb68b;
		line-height: 25px;
	}

	.news_cont_right_text_item p {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 400;
		color: #8f8f8f;
		line-height: 20px;
	}

	.news_cont_right_text_hr {
		border-bottom: 1px solid #d7d7d7;
	}

	.news_cont_right_text_hr:nth-of-type(3) {
		/* width: 508px; */
		border-bottom: none;
	}

	.news_cont_right_text_item2 {
		padding: 24px 40px;
	}

	.news_cont_right_text_item2 h1 {
		font-size: 18px;
		font-weight: 500;
		color: #444444;
		line-height: 25px;
	}

	.news_cont_right_text_item2 p {
		margin-top: 10px;
		font-size: 14px;
		font-weight: 400;
		color: #8f8f8f;
		line-height: 20px;
	}

	/* 集团产业 */
	.GroupIndustry {
		width: 100vw;
		/* height: 800px; */
		background: #ffffff;
	}

	.GroupIndustry_cont {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.GroupIndustry_cont_left_title {
		font-size: 25px;
		font-weight: 600;
		color: #444444;
		line-height: 50px;
		margin: 84px 0 40px 0;
	}

	.GroupIndustry_cont_left_img_f {
		/* width: 793px; */
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.GroupIndustry_cont_left_img {
		margin-bottom: 24px;
		position: relative;
	}

	.GroupIndustry_cont_left_img img:first-child {
		width: 580px;
		height: 350px;
		background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
	}

	.GroupIndustry_cont_left_text {
		position: absolute;
		left: 32px;
		bottom: 18px;
	}

	.GroupIndustry_cont_left_text p {
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		line-height: 20px;
		margin-top: 10px;
	}

	.GroupIndustry_cont_left_text span {
		font-size: 22px;
		/* font-weight: 600; */
		color: #ffffff;
		text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
	}

	.GroupIndustry_cont_right {
		position: relative;
		margin-top: 98px;
	}

	.GroupIndustry_cont_right_title {
		cursor: pointer;
		float: right;
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		line-height: 22px;
		margin-bottom: 54px;
	}

	.GroupIndustry_cont_right_img {
		width: 384px;
		height: 546px;
	}

	.GroupIndustry_cont_right_img2 {
		position: absolute;
		bottom: 24px;
	}

	.GroupIndustry_cont_right_text {
		position: absolute;
		left: 45px;
		bottom: 45px;
	}

	.GroupIndustry_cont_right_text p {
		font-size: 24px;
		font-weight: 600;
		color: #ffffff;
		line-height: 33px;
	}

	.GroupIndustry_cont_right_text span {
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		line-height: 20px;
	}

	/* 关于企业 */
	.AboutEnterprises {
		width: 100vw;
		padding-bottom: 100px;
		/* height: 856px; */
		background-image: url("../assets/img/guanyu/guanyu_bg.png");
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.AboutEnterprises_cont {
		width: 1200px;
		margin: 0 auto;
		height: 100%;
	}

	.AboutEnterprises_cont_top {
		display: flex;
		justify-content: space-between;
	}

	.AboutEnterprises_cont_top_left {
		margin-top: 143px;
		width: 600px;
	}

	.AboutEnterprises_cont_top_left>h1 {
		font-size: 25px;
		font-weight: 600;
		color: #cfb68b;
		line-height: 50px;
	}

	.AboutEnterprises_cont_top_left p {
		margin-top: 36px;
		font-size: 16px;
		font-weight: 400;
		color: #ffffff;
		line-height: 40px;
	}

	.AboutEnterprises_cont_top_left_btn {
		width: 160px;
		height: 40px;
		border: 1px solid rgba(255, 255, 255, 0.8);
		margin-top: 81px;
		display: flex;
		align-items: center;
	}

	.AboutEnterprises_cont_top_left_btn img {
		width: 24px;
		height: 24px;

		margin-left: 8px;
	}

	.AboutEnterprises_cont_top_left_btn span {
		margin-left: 32px;
		/* font-size: 16px; */
		font-weight: 400;
		color: #ffffff;
		line-height: 22px;
	}

	.AboutEnterprises_cont_top_right {
		position: relative;
		width: 487px;
		height: 517px;
		margin-top: 115px;
	}

	.AboutEnterprises_cont_top_right img {
		position: absolute;
		z-index: 1;
	}

	.AboutEnterprises_cont_top_right Div {
		width: 317px;
		height: 408px;
		background: #1a2936;
		position: absolute;
		top: 64px;
		left: -33px;
	}
</style>